import React from 'react';

const Search = () => {
  return (
    <div className="search-page">
      <h1>Search Products</h1>
      <p>Search for beauty products to compare ingredients.</p>
      <input type="text" placeholder="Search products..." />
    </div>
  );
};

export default Search;