// src/pages/Login.jsx
import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/logo.png'; // Make sure this path is correct

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  
  // Get the page they were trying to access
  const from = location.state?.from?.pathname || '/';

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Here you would add your actual authentication logic
    // This is just a placeholder
    try {
      // Call your backend API for authentication
      // const response = await loginUser(email, password);
      
      // For now, just simulate successful login
      localStorage.setItem('isAuthenticated', 'true');
      
      // Redirect to the page they were trying to access
      navigate(from, { replace: true });
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <div className="login-container" style={{ 
      backgroundImage: 'linear-gradient(#c6dbff,#c6dbff)',
      minHeight: '70vh',
      display: 'flex',
        padding: '0px 0px 0px 0px'
    }}>
      <div className="login-form-container" style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <img src={logo} alt="Beauty Double" style={{ width: '120px', marginBottom: '30px' }} />
        
        <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
          <div style={{ marginBottom: '20px' }}>
            <label htmlFor="email" style={{ display: 'block', marginBottom: '8px' }}>Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ 
                width: '100%', 
                padding: '12px', 
                borderRadius: '8px',
                border: '1px solid rgba(0,0,0,0.1)',
                backgroundColor: 'rgba(255,255,255,0.8)'
              }}
              required
            />
          </div>
          
          <div style={{ marginBottom: '20px' }}>
            <label htmlFor="password" style={{ display: 'block', marginBottom: '8px' }}>Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ 
                width: '100%', 
                padding: '12px', 
                borderRadius: '8px',
                border: '1px solid rgba(0,0,0,0.1)',
                backgroundColor: 'rgba(255,255,255,0.8)'
              }}
              required
            />
          </div>
          
          <div style={{ textAlign: 'right', marginBottom: '20px' }}>
            <Link to="/forgot-password" style={{ textDecoration: 'none', color: '#000' }}>
              Forgot Password?
            </Link>
          </div>
          
          <button 
            type="submit" 
            style={{ 
              width: '100%', 
              padding: '14px', 
              backgroundColor: '#0046d8', 
              color: 'white',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer',
              fontSize: '16px'
            }}
          >
            Login
          </button>
          
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            Are you New here? 
            <Link to="/signup" style={{ textDecoration: 'none', color: '#c5d7f7', marginLeft: '5px' }}>
              Sign In
            </Link>
          </div>
        </form>
      </div>
      
      <div className="login-image-container" style={{ 
        flex: 1, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        backgroundImage: `url('https://beauty-double-frontend.s3.us-east-1.amazonaws.com/signup2.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // borderRadius: '20px'
      }}>
      </div>
    </div>
  );
};

export default Login;