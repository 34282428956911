// Header.jsx
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Import icons (you can use react-icons, material-ui icons, or any icon library you prefer)

import {IoHomeOutline, IoTrendingUpOutline } from "react-icons/io5";
import { AiOutlineSearch } from "react-icons/ai";
import { IoMdHeartEmpty } from "react-icons/io";
import { RiNotification4Line } from "react-icons/ri";
import { RiUser6Line } from "react-icons/ri";



import './Header.css';


const Header = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const navItems = [
    { path: '/', icon: <IoHomeOutline />, label: 'Home' },
    { path: '/search', icon: <AiOutlineSearch />, label: 'Search' },
    { path: '/likes', icon: <IoMdHeartEmpty />, label: 'Likes' },
    { path: '/notifications', icon: <RiNotification4Line />, label: 'Notifications' },
    { path: '/trending', icon: <IoTrendingUpOutline />, label: 'Trending' },
    { path: '/profile', icon: <RiUser6Line />, label: 'Profile' },
  ];

  return (
    <header className="header">
      <nav className="nav-container">
        {navItems.map((item) => (
          <Link 
            key={item.path} 
            to={item.path} 
            className={`nav-item ${currentPath === item.path ? 'active' : ''}`}
          >
            {currentPath === item.path && <div className="hanging-ball-div"> <div className="hanging-ball"></div></div>}
            <div className="icon">{item.icon}</div>
            <div className="label">{item.label}</div>
          </Link>
        ))}
      </nav>
    </header>
  );
};

export default Header;