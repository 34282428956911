import React from 'react';
import './Home.css'; // We'll create this
import logo from '../assets/logo.png';
import { FaUserFriends } from 'react-icons/fa';
import {IoHomeOutline, IoTrendingUpOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="home-page">
      <div className="hero-section">
      <div className="home-logo-container">
          <img src={logo} alt="Beauty Double Logo" className="company-logo" />
        </div>
        <div className="hero-content">
          <h1>Discover Your Beauty Double - Test # 2</h1>
          <div className="intro-text">
            <p>Say hello to Beauty Double, your personal beauty detective on a mission to find the perfect match for your favorite products!</p>
            <p>Ever wonder if there's a product out there with the same silky texture, glowing finish, or superstar ingredients? We've got you covered.</p>
            <p>Our Beauty Double Algorithm dives deep into ingredients, textures, and formulations to help you discover identical twins that don't just look good - they feel and perform just like the originals.</p>
            <p>No more squinting at confusing ingredient lists or wasting time on trial and error. We simplify the science behind beauty, so you can focus on what really matters: achieving your glow. Whether you're hunting for a budget-friendly twin or exploring new favorites, Beauty Double makes smart beauty decisions effortless.</p>
          </div>
          <div className="cta-container">          
                <Link to="/login" className="cta-button">
                    <FaUserFriends className="button-icon" /> Join the Squad
                </Link>
                <Link to="/trending" className="secondary-button">
              <IoTrendingUpOutline className="button-icon" /> What's Trending
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;