import React from 'react';

const Likes = () => {
  return (
    <div className="home-page">
      <h1>Likes Home Page</h1>
      <p>Welcome to Beauty Double, your beauty product comparison tool.</p>
    </div>
  );
};

export default Likes;