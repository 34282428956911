import React from 'react';

const Notifications = () => {
  return (
    <div className="home-page">
      <h1> Notifications Home Page</h1>
      <p> Welcome to Beauty Double, your beauty product comparison tool.</p>
    </div>
  );
};

export default Notifications;